* {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0px;
}

.paragraph {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

html,
body {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
}

@media only screen and (min-width: 1024px) {
  html,
  body {
    font-family: Roboto;
    background: grey;
    font-size: 18px;
  }
}
@media only screen and (max-width: 1024px) {
  html,
  body {
    font-family: Roboto;
    background: grey;
    font-size: 22px;
  }
}

.login-page {
  padding: 5% 0 5%;
  margin: 0 auto 100px;
  max-width: 360px;
  position: relative;
}

.login-form {
  box-shadow: 0 0 27px 0 rgba(84, 84, 84, 0.62);
  background-color: #7c8184 !important;
  border-radius: 25px;
}

.login-form-header {
  padding-top: 16px;
}

.login-from-row {
  padding-top: 16px;
  padding-bottom: 16px;
}

.login-form-font-header {
  font-size: 30px;
  font-weight: bold;
}

.login-form-font-header span {
  color: #007c9b;
}

.react-select > div {
  margin: -1px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.mdb tbody tr td {
  text-align: center;
  font-size: 14px;
}
.mdb thead {
  text-align: center;
  font-size: 16px;
}
